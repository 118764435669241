import React from "react";
import Table from "./Table";
import { dateFormat, fmtCurrency } from "../../utils/helper";
import Icon from "../Icon";
import { useNavigate } from "react-router-dom";

const ClientsTable = ({ data }) => {
  const columns = [
    "Client Type",
    "Client name",
    "Expiry Date",
    "Membership Plan",
    "Amount paid",
    "Status",
  ];
  const navigate = useNavigate();

  const getActualPrice = (row) => {
    const benefit = row?.benefit?.priceOptions.find(
      (item) => item?.duration === row?.benefitDuration
    );
    return benefit?.price;
  };
  return (
    <Table columns={columns}>
      {data?.map((row, index) => (
        <tr className="pointer grey-bg" key={index}>
          <td>
            <span
              className={
                row?.clientType === "PROVIDER" ? "non-flancer" : "flancer"
              }
            >
              <Icon id="user" width="24" height="24" />
            </span>
          </td>
          <td>{row?.firstName + " " + row?.lastName}</td>
          <td> {dateFormat(row.renewalDate, "MMM dd, yyyy")}</td>
          <td>{row?.benefit?.benefitName}</td>
          <td>{fmtCurrency(getActualPrice(row) || 0)}</td>
          <td>
            <span
              className={`status ${
                row?.status === "ACTIVE"
                  ? "employee-active"
                  : row?.status === "EXPIRED" || row?.status === "INACTIVE"
                  ? "status-inactive"
                  : "pending"
              }`}
            >
              {row?.status}
            </span>
          </td>
          <td
            className="details"
            onClick={() =>
              navigate(`/clients/${row?.id}?clientType=${row?.clientType}`)
            }
          >
            See details
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default ClientsTable;
