import React from "react";

const BusinessInfoHeader = ({
  previewProfile,
  GalleryAdd,
  uploadingImage,
  handleImageChange,
}) => {
  return (
    <div className="businessInfo--header">
      <div className="businessInfo--header__deets">
        <h1>Add Business Information</h1>
        <p>Details of your business</p>
      </div>
      <div className="businessInfo--header__info">
        <div className="img__container">
          <img src={previewProfile || GalleryAdd} alt="GalleryAdd" />
        </div>
        <div className="upload__deets">
          <p className="upload">Upload Logo</p>
          <p className="pic-format">upload logo (.jpg, .jpeg, .png) file</p>
          <div className="upload__btn">
            <span>{uploadingImage ? "Uploading..." : "Upload"}</span>
            <input
              type="file"
              className="file-input"
              accept=".jpg, .jpeg, .png"
              onChange={handleImageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoHeader;
