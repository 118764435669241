import React, { useEffect, useState } from "react";
import Input from "../../components/forms/Input";
import Button from "../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { Selectbox } from "../../components/forms";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  fetchProviderCategory,
  registerUser,
} from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import nProgress from "nprogress";
import CheckboxGroup from "../../components/forms/SelectBox";
import SelectBox from "../../components/forms/SelectBox";
import toast from "react-hot-toast";

const SignUp = () => {
  const [formError, setFormError] = React.useState({});
  const [checkedItems, setCheckedItems] = useState([]);
  const { user, isLoading, benefitCategory } = useSelector(
    (store) => store.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneRegExp =
    /((^090)([0-9]))|((^070)([0-9]))|((^080)([0-9]))|((^091)([0-9]))|((^071)([0-9]))|((^081)([0-9]))(\d{7})/;
  const schema = Yup.object({
    businessOfficialEmail: Yup.string()
      .required("Email field is required")
      .email("Invalid email address"),
    password: Yup.string()
      .required("Required")
      .min(8, "Password must be at least 8 characters")
      .matches(/^(?=.*[!@#$%^&*_-])/, "Must Contain One Special Character"),
    businessName: Yup.string().required("business name is required"),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(11, "Phone number cannot be less than 11 characters")
      .max(14, "Phone number cannot be longer than 14 characters"),
  });

  const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
    useFormik({
      initialValues: {
        businessOfficialEmail: "",
        password: "",
        businessName: "",
        phone: "",
        refCode: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        if (checkedItems.length < 1) {
          toast.error("Service Category is empty");
          return;
        }
        dispatch(
          registerUser({
            businessName: values.businessName,
            email: values.businessOfficialEmail,
            phone: values.phone,
            categories: [...checkedItems],
            password: values.password,
            referralCode: values?.refCode,
          })
        );
      },
    });

  const handleCheckboxChange = (item) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(item.value)) {
        // Uncheck the item by filtering it out
        return prevCheckedItems.filter((value) => value !== item.value);
      } else {
        return [...prevCheckedItems, item.value];
      }
    });
  };
  const dropDownItem = [
    {
      name: "Gym",
      value: "Gym",
    },
    {
      name: "Health",
      value: "Health",
    },
    {
      name: "Spa",
      value: "Spa",
    },
  ];

  useEffect(() => {
    dispatch(fetchProviderCategory());
  }, []);

  useEffect(() => {
    if (isLoading) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [isLoading]);
  useEffect(() => {
    if (user?.data?.token) {
      navigate("/onboarding");
    }
  }, [navigate, user]);
  return (
    <div className="auth__form--content">
      <div className="auth__form--header">
        <div className="header__container">
          <div className="header__container--content">
            <h3 className="tx-c">Create an Account.</h3>
            <p className="tx-c">Manage your plans and clients</p>
          </div>
        </div>
      </div>
      <form className="__form" onSubmit={handleSubmit}>
        <Input
          type="text"
          id="businessName"
          value={values.businessName}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Business Name"
          name="businessName"
          error={
            touched.businessName && errors.businessName ? (
              <small className="error">{errors.businessName}</small>
            ) : null
          }
        />
        <Input
          type="email"
          id="businessOfficialEmail"
          value={values.businessOfficialEmail}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Business Official Email"
          name="businessOfficialEmail"
          error={
            touched.businessOfficialEmail && errors.businessOfficialEmail ? (
              <small className="error">{errors.businessOfficialEmail}</small>
            ) : null
          }
        />

        <Input
          type="tel"
          id="phone"
          label="Business phone number"
          name="phone"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            (touched.phone && errors.phone) || formError?.phone ? (
              <small className="error">
                {errors.phone || formError?.phone}
              </small>
            ) : null
          }
        />

        <Input
          type="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Password"
          name="password"
          error={
            touched.password && errors.password ? (
              <small className="error">{errors.password}</small>
            ) : null
          }
        />
        {/* 
        <CheckboxGroup
          options={[
            {
              name: "Gym",
              value: "Gym",
            },
            {
              name: "Health",
              value: "Health",
            },
            {
              name: "Spa",
              value: "Spa",
            },
          ]}
          id="serviceCategory"
          name="serviceCategory"
          placeholder="Select Service category"
          onChange={handleChange}
          value={values.serviceCategory}
        /> */}
        <SelectBox
          dropDownItem={dropDownItem}
          checkedItems={checkedItems}
          handleCheckboxChange={handleCheckboxChange}
          setCheckedItems={setCheckedItems}
        />
        <Input
          type="text"
          id="refCode"
          value={values.refCode}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Enter referral code (Optional)"
          name="refCode"
        />

        <Button
          variant="primary"
          type="submit"
          //   className="mt-40"
          block
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Sign up"}
        </Button>
      </form>

      <p className=" tx-c extralink">
        Already a Flancer?{" "}
        <Link className="btn btn--link btn--link-secondary" to="/auth/login">
          Login
        </Link>
      </p>
      <p className=" tx-c acknwmnt">
        By clicking “SIGN UP", you acknowledge that you have read and
        understood, and agreed to our and Privacy Policy.{" "}
        <a
          className="btn btn--link btn--link-secondary"
          href="https://www.flanceapp.com/terms"
          target={`_blank`}
        >
          Terms of Use
        </a>{" "}
        and{" "}
        <a
          className="btn btn--link btn--link-secondary"
          href="https://www.flanceapp.com/privacy"
          target={`blank`}
        >
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );
};

export default SignUp;
