import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon";
import SelectBranch from "../../components/SelectBranch";
import BusinessInfo from "../../components/BusinessInfo";
import PlanPricing from "../../components/PlanPricing";
import AddClients from "../../components/AddClients";
import { useDispatch, useSelector } from "react-redux";
import { changeNavtitle } from "../../features/user/userSlice";

const Onboarding = () => {
  const { steps } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      changeNavtitle(
        steps === 0
          ? "Select Branch Type"
          : steps === 1
          ? "Add Business Information"
          : steps === 2
          ? "Add Price Plans"
          : steps === 3
          ? "Add Client"
          : "Explore"
      )
    );
  }, [steps]);

  return (
    <div className="onboarding">
      <div className="onboarding--header">
        <div
          className={`onboarding--header__items ${steps > 0 ? "active" : ""} ${
            steps === 0 ? "current" : ""
          }`}
        >
          <div className="item">
            <span>
              <Icon width={25} height={25} id="notes" />
            </span>
            <p>Add Business Info</p>
          </div>
        </div>
        <div className={`tab ${steps ? "activeTab" : ""}`}></div>

        <div
          className={`onboarding--header__items ${steps > 1 ? "active" : ""} ${
            steps === 1 ? "current" : ""
          }`}
        >
          <div className="item">
            <span>
              <Icon width={25} height={25} id="lists" />
            </span>
            <p>Add Price Plans</p>
          </div>
        </div>
        <div className={`tab ${steps > 1 ? "activeTab" : ""}`}></div>
        <div
          className={`onboarding--header__items ${steps > 2 ? "active" : ""} ${
            steps === 2 ? "current" : ""
          }`}
        >
          <div className="item">
            <span>
              <Icon width={25} height={25} id="contacts" />
            </span>
            <p>Add Clients</p>
          </div>
        </div>
      </div>
      <div className="header--body">
        {/* {steps === 0 && <SelectBranch />} */}
        {steps === 0 && <BusinessInfo />}
        {steps === 1 && <PlanPricing />}
        {steps === 2 && <AddClients />}
      </div>
    </div>
  );
};

export default Onboarding;
