import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import SearchAddContainer from "../../components/SearchAddContainer";
import ClientsTable from "../../components/table/ClientsTable";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../components/PageLoader";
import { dateFormat, fmtCurrency, paginate } from "../../utils/helper";
import {
  changeNavtitle,
  fetchProviderBenefit,
  getProviderSubscribers,
} from "../../features/user/userSlice";
import Pagination from "../../components/table/Pagination";
import AddClientsFIleModal from "../../components/modal/AddClientsFIleModal";
import { AddClientsModal } from "../../components/modal/AddClientsModal";
import AddClientSuccess from "../../components/modal/AddClientsSucces";
import MobileTable from "../../components/table/MobileTable";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const [userSearch, setUserSearch] = useState("");
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedMemberlist, setPaginatedMemberlist] = useState([]);
  const [page, setPage] = useState(0);
  const { subscriberList, isLoading, submitClientsManuallySuccess } =
    useSelector((store) => store.user);
  const [openAddClientsCsvModal, setOpenAddClientsCsvModal] = useState(false);
  const [closeClientModal, setCloseClientModal] = useState(false);
  const [csvSelected, setCsvSelected] = useState("");
  const [manualClientUploadModalSuccess, setManualClientUploadModalSuccess] =
    useState(false);
  const navigate = useNavigate();
  const [selectedFilter, setSelectedFilter] = useState("");
  const [userFilteredByStatus, setUserFilteredByStatus] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProviderSubscribers());
    dispatch(changeNavtitle("Clients"));
  }, []);
  // const memberListFilter = subscriberList?.page?.content?.filter((user) => {
  //   if (
  //     user?.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
  //     user?.lastName?.toLowerCase().includes(userSearch.toLowerCase())
  //   ) {
  //     return user;
  //   }
  // });
  useEffect(() => {
    dispatch(fetchProviderBenefit());
  }, [dispatch]);

  const clientListFilter = userFilteredByStatus?.filter((user) => {
    if (
      user?.firstName?.toLowerCase().includes(userSearch.toLowerCase()) ||
      user?.lastName?.toLowerCase().includes(userSearch.toLowerCase())
    ) {
      return user;
    }
  });

  useEffect(() => {
    if (paginatedMemberlist) {
      setPaginatedList(paginatedMemberlist[page]);
    }
  }, [paginatedMemberlist, page]);
  useEffect(() => {
    if (clientListFilter) {
      setPaginatedMemberlist(paginate(clientListFilter));
    }
  }, [clientListFilter?.length]);

  useEffect(() => {
    setManualClientUploadModalSuccess(submitClientsManuallySuccess);
    if (submitClientsManuallySuccess) {
      setCloseClientModal(false);
    }
  }, [submitClientsManuallySuccess]);
  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter?.value === "" || selectedFilter?.value === "All") {
        setUserFilteredByStatus(subscriberList?.page?.content);
        return;
      } else {
        const numOfPlanStatus = subscriberList?.page?.content?.filter(
          (user) => {
            if (
              user?.clientType?.toLowerCase() ===
              selectedFilter?.value.toLowerCase()
            ) {
              return user;
            }
          }
        );
        setUserFilteredByStatus(numOfPlanStatus);
      }
    } else {
      setUserFilteredByStatus(subscriberList?.page?.content);
    }
  }, [subscriberList?.page?.content, selectedFilter]);

  const getActualPrice = (row) => {
    const benefit = row?.benefit?.priceOptions.find(
      (item) => item?.duration === row?.benefitDuration
    );
    return benefit?.price;
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="clients">
          <div className="clients__header">
            <div className="btns">
              <Button
                variant="secondary"
                type="submit"
                block
                //   disabled={isLoading}
                onClick={() => setCloseClientModal(true)}
              >
                <span>+</span>
                Add New Clients
              </Button>
              <Button
                variant="primary"
                type="submit"
                className=""
                block
                onClick={() => setOpenAddClientsCsvModal(true)}
                //   disabled={isLoading}
              >
                <span>
                  <Icon id={"downloadBlack"} height={"24"} width={"24"} />
                </span>
                IMPORT CLIENTS
              </Button>
            </div>
          </div>
          <div className="table__header">
            <h4>Clients</h4>
            <SearchAddContainer
              changeInput={(e) => setUserSearch(e.target.value)}
              filterOptions={[
                { name: "All", value: "All" },
                { name: "Provider", value: "Provider" },
                { name: "Flancer", value: "flance" },
              ]}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          </div>
          <div className="clients__table">
            <div className="table-container">
              <ClientsTable data={paginatedList} />
              <div className="smaller__table">
                {paginatedList?.map((user, index) => (
                  <MobileTable>
                    <div
                      className="small__dashboard--table"
                      onClick={() =>
                        navigate(
                          `/clients/${user?.id}?clientType=${user?.clientType}`
                        )
                      }
                    >
                      <div className="smallTable__right">
                        <div className="image-title">
                          <span
                            className={
                              user?.clientType === "PROVIDER"
                                ? "non-flancer"
                                : "flancer"
                            }
                          >
                            <Icon id="user" width="24" height="24" />
                          </span>
                          <div className="contain">
                            <h3>{user?.firstName + " " + user?.lastName}</h3>
                            <p className="deets">
                              {fmtCurrency(getActualPrice(user) || 0)}
                            </p>
                          </div>
                        </div>
                        <p className="date--deet">
                          {dateFormat(user.renewalDate, "MMM dd, yyyy hh:mm a")}
                        </p>
                      </div>
                      <div className="smallTable__left">
                        <p className="amount">{user?.benefit?.benefitName}</p>
                        <span
                          className={`status ${
                            user?.status === "ACTIVE"
                              ? "employee-active"
                              : user?.status === "EXPIRED" ||
                                user?.status === "INACTIVE"
                              ? "status-inactive"
                              : "pending"
                          }`}
                        >
                          {user?.status}
                        </span>
                      </div>
                    </div>
                  </MobileTable>
                ))}
              </div>
              <Pagination
                itemList={paginatedMemberlist}
                page={page}
                setPage={setPage}
              />
            </div>
          </div>
          {openAddClientsCsvModal && (
            <AddClientsFIleModal
              openAddClientsCsvModal={openAddClientsCsvModal}
              setOpenAddClientsCsvModal={setOpenAddClientsCsvModal}
              setCsvSelected={setCsvSelected}
              csvSelected={csvSelected}
            />
          )}

          {closeClientModal && (
            <AddClientsModal
              closeClientModal={closeClientModal}
              setCloseClientModal={setCloseClientModal}
            />
          )}
          <AddClientSuccess
            successModal={manualClientUploadModalSuccess}
            iconID={"checkSuccess"}
            subtext={`You have successfully added a client. `}
            title={`Congratulations!`}
            iconWidth={64}
            iconHeight={64}
            setManualClientUploadModalSuccess={
              setManualClientUploadModalSuccess
            }
            clients={true}
            setCloseClientModal={setCloseClientModal}
          />
        </div>
      )}
    </>
  );
};

export default Clients;
