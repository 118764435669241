import React from 'react';
// import loader from 'assets/svgs/spinner.svg';
// import Lottie from 'react-lottie';
// import { useLottie } from 'lottie-react';
// import Lottie from 'lottie-react';

import Lottie from 'react-lottie';
import * as animationData from '../assets/lotties/lf30_editor_q1o4e5ak.json';

export default function Spinner() {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div className="spinner_loader">
			<Lottie
				options={defaultOptions}
				width={250}
				height={'auto'}
				position={'absolute'}
				className={'lottie'}
			/>
		</div>
	);
}
