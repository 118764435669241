import React, { useEffect, useState } from "react";
import ActiveBenefits from "../../components/ActiveBenefits";
import skeletonLoading from "../../assets/svgs/nolist.svg";
import SearchAddContainer from "../../components/SearchAddContainer";
import ClientsTable from "../../components/table/ClientsTable";
import Pagination from "../../components/table/Pagination";
import { dateFormat, fmtCurrency, paginate } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNavtitle,
  getProviderCredit,
  getProviderCreditTransactions,
  getProviderSubscribers,
} from "../../features/user/userSlice";
import Spinner from "../../components/PageLoader";
import { useNavigate } from "react-router-dom";
import MobileTable from "../../components/table/MobileTable";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import DashboardTable from "../../components/table/DashboardTable";
import PassModal from "../../components/modal/PassModal";
import SuccessModal from "../../components/modal/SuccessModal";

const Dashboard = () => {
  const [userSearch, setUserSearch] = useState("");
  const [paginatedList, setPaginatedList] = useState([]);
  const [paginatedTransactionlist, setPaginatedTransactionlist] = useState([]);
  const [page, setPage] = useState(0);
  const [openFlancePassModal, setOpenFlancePassModal] = useState(false);

  const { isLoading, providerCredit, transactions, subscriberList } =
    useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderCreditTransactions());
    dispatch(getProviderCredit());
    dispatch(changeNavtitle("Dashboard"));
    dispatch(getProviderSubscribers());
  }, []);

  useEffect(() => {
    if (paginatedTransactionlist) {
      setPaginatedList(paginatedTransactionlist[page]);
    }
  }, [paginatedTransactionlist, page]);
  useEffect(() => {
    if (transactions) {
      setPaginatedTransactionlist(paginate(transactions));
    }
  }, [transactions?.length]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {
            <div className="overview">
              <div className="overview__header">
                <div className="dash--right">
                  <div className="dash--right__activeSubs">
                    <span>
                      <Icon id="twoPeople" width="24" height="24" />
                    </span>
                    <div className="__text">
                      <p>active subscribers </p>
                      <h2>{subscriberList?.totalActiveClients}</h2>
                    </div>
                  </div>
                  <div className="dash--right__activeSubs ">
                    <span>
                      <Icon id="clock" width="24" height="24" />
                    </span>
                    <div className="__text">
                      <p>upcoming settlement amount </p>
                      <h2>{fmtCurrency(providerCredit?.balance)}</h2>
                    </div>
                  </div>
                </div>
                <div className="verification">
                  <div className="verification__balance">
                    <p>Client Code Verification</p>
                  </div>
                  <div className="verification__btn">
                    <Button
                      variant="primary"
                      type="submit"
                      className="mt-40"
                      block
                      disabled={isLoading}
                      onClick={() => setOpenFlancePassModal(true)}
                    >
                      Enter code
                    </Button>
                  </div>
                </div>
              </div>
              <div className="overview__container">
                <h4>Transaction History</h4>
              </div>
              {transactions?.length >= 1 ? (
                <>
                  {" "}
                  <div className="overview__table">
                    <div className="table-container">
                      <DashboardTable data={paginatedList} />

                      <div className="smaller__table">
                        {paginatedList?.map((user, index) => (
                          <MobileTable>
                            <div className="small__dashboard--table">
                              <div className="smallTable__right">
                                <h3>{user?.transactionType}</h3>
                                <p className="deets">{user?.transactionId}</p>
                                <p className="date--deet">
                                  {dateFormat(
                                    user.dateCreated,
                                    "MMM dd, yyyy hh:mm a"
                                  )}
                                </p>
                              </div>
                              <div className="smallTable__left">
                                <p className="amount">
                                  {fmtCurrency(user?.amount)}
                                </p>
                                <span
                                  className={`status ${
                                    user?.operationType === "CR"
                                      ? "employee-active"
                                      : user?.operationType === "DR" ||
                                        user?.subscriptionStatus === "CANCELLED"
                                      ? "status-inactive"
                                      : "pending"
                                  }`}
                                >
                                  {user?.operationType === "CR"
                                    ? "Credit"
                                    : "Debit"}
                                </span>
                              </div>
                            </div>
                          </MobileTable>
                        ))}
                      </div>
                      <Pagination
                        itemList={paginatedTransactionlist}
                        page={page}
                        setPage={setPage}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="mt-40">
                  <ActiveBenefits
                    icon={skeletonLoading}
                    text={`No transaction yet. They'll show here once you start`}
                    centerHeight={false}
                  />
                </div>
              )}
              <PassModal
                openFlancePassModal={openFlancePassModal}
                setOpenFlancePassModal={setOpenFlancePassModal}
              />
              <SuccessModal />
            </div>
          }
        </>
      )}
    </>
  );
};

export default Dashboard;
