import React from "react";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Input } from "../../components/forms";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addContactPerson } from "../../features/user/userSlice";

const KeyPerson = () => {
  const { submitClientsManuallyLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { handleSubmit, handleChange, handleBlur, touched, values } = useFormik(
    {
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      onSubmit: (values) => {
        dispatch(addContactPerson(values));
      },
    }
  );
  return (
    <div className="keyperson">
      <div className="keyperson__header">
        <h3>Key Person</h3>
        <p>
          Your point of contact can be your HR, Payroll Officer, Admin etc. Note
          that an email will be sent to notify them.
        </p>
      </div>
      <div className="keyperson__body">
        <div className="keyperson__update">
          <Button
            variant="primary"
            type="submit"
            className="update"
            block
            size={"md"}
            onClick={handleSubmit}
          >
            {submitClientsManuallyLoading ? "Updating..." : "Update"}
          </Button>
        </div>
        <div className="keyperson__body--form mt-24">
          <div className="inputgroup">
            <Input
              type="text"
              id="firstName"
              label="FIrst Name"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              placeholder="First Name"
            />
            <Input
              type="text"
              id="lastName"
              label="Last Name"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              placeholder="First Name"
            />
          </div>
          <Input
            type="text"
            id="email"
            label="Email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Email"
          />
          <Input
            type="text"
            id="phone"
            label="Phone Number"
            name="phone"
            value={values.phone}
            onChange={handleChange}
            placeholder="Phone Number"
          />
        </div>
      </div>
    </div>
  );
};

export default KeyPerson;
