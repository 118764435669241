import React from "react";
import { Link, useLocation } from "react-router-dom";
import Icon from "./Icon";
import cancelIcon from "../assets/svgs/cancel.svg";
import { navigations } from "../utils/navigations";
import Logo from "../assets/svgs/logo.svg";

const SidebarSlide = ({ setIsSidebarOpen, isSidebarOpen, splitLocation }) => {
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const changeRoute = () => {
    // setActive(link);
    closeSidebar();
  };

  const location = useLocation();

  return (
    <div className={`${isSidebarOpen ? "backdrop" : ""} sidebarContainer `}>
      <aside
        className={`${
          isSidebarOpen ? "sidebar-slide show-sidebar " : "sidebar-slide "
        }`}
      >
        <div className="sidebar__container ">
          <div className="sidebar__header">
            <div className="dashboard__sidenav--banner">
              <img src={Logo} alt="fla" />
            </div>

            <span className="close-side" onClick={closeSidebar}>
              <img src={cancelIcon} alt="fla" />
            </span>
          </div>
          <div className="sidebar__links">
            {location?.pathname === "/onboarding" ? (
              <>
                <ul>
                  <Link
                    to={"onboarding"}
                    // onClick={() => setActive(navigation.link)}
                    className={`navlinks ${"active"} active`}
                  >
                    <Icon width="24px" height="24px" id={`onboarding`} />
                    <p>{"ONBOARDING"}</p>
                  </Link>
                </ul>
              </>
            ) : (
              <>
                {" "}
                <ul>
                  {navigations.map((navigation) => {
                    return (
                      <Link
                        to={navigation.link}
                        // onClick={() => setActive(navigation.link)}
                        onClick={() => changeRoute()}
                        className={`navlinks ${
                          splitLocation[1] === navigation.navName
                            ? "active"
                            : ""
                        } `}
                        key={navigation.id}
                      >
                        <Icon
                          width="25px"
                          height="25px"
                          id={`${
                            splitLocation[1] === navigation?.navName
                              ? navigation?.coloredIcon
                              : navigation?.icon
                          }`}
                        />
                        <p>{navigation?.name}</p>
                      </Link>
                    );
                  })}
                </ul>
              </>
            )}
          </div>
        </div>
      </aside>
    </div>
  );
};

export default SidebarSlide;
