import React from "react";
import Table from "./Table";
import { dateFormat, fmtCurrency } from "../../utils/helper";
import Icon from "../Icon";

const DashboardTable = ({ data }) => {
  const columns = ["Date", "Description", "Amount", "Reference", "Status"];
  return (
    <Table columns={columns}>
      {data?.map((row, index) => (
        <tr className="pointer grey-bg" key={index}>
          <td> {dateFormat(row.dateCreated, "MMM dd, yyyy hh:mm a")}</td>
          <td>{row?.transactionType}</td>
          <td>{fmtCurrency(row?.amount)}</td>
          <td>{row?.transactionId}</td>
          <td>
            <span
              className={`status ${
                row?.operationType === "CR"
                  ? "employee-active"
                  : row?.operationType === "DR" ||
                    row?.subscriptionStatus === "CANCELLED"
                  ? "status-inactive"
                  : "pending"
              }`}
            >
              {row?.operationType === "CR" ? "Credit" : "Debit"}
            </span>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default DashboardTable;
