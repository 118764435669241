import React from 'react';
import Thead from './Thead';

const Table = ({ columns, children }) => {
	return (
		<table>
			<Thead columns={columns} />
			{children}
		</table>
	);
};

export default Table;
