import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardSIdebar from "../components/DashboardSIdebar";
import Spinner from "../components/PageLoader";
import SidebarSlide from "../components/SlidingSidebar";
import DashboardHeader from "../components/DashboardHeader";
// import { useSelector, useDispatch } from "react-redux";
// import { getBusinessDetails } from "../features/user/userSlice";
// import Spinner from "../components/PageLoader";
// import { Toaster } from "react-hot-toast";

const DashboardLayout = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  //   const { isLoading } = useSelector((store) => store.user);
  //   const dispatch = useDispatch();
  const [slidingSidebar, setSlidingSidebar] = useState(false);

  //   useEffect(() => {
  //     dispatch(getBusinessDetails());
  //   }, []);

  return (
    <>
      {false ? (
        <Spinner />
      ) : (
        <>
          {/* <div className="toaster__style">
            <Toaster position="top-right" reverseOrder={false} />
          </div> */}
          <SidebarSlide
            setIsSidebarOpen={setSlidingSidebar}
            isSidebarOpen={slidingSidebar}
            splitLocation={splitLocation}
          />
          <div className="dashboard">
            <DashboardSIdebar
              slidingSidebar={slidingSidebar}
              setSlidingSidebar={setSlidingSidebar}
              splitLocation={splitLocation}
            />
            <div className="dashboard__main">
              <DashboardHeader
                splitLocation={splitLocation}
                setSlidingSidebar={setSlidingSidebar}
                slidingSidebar={slidingSidebar}
              />
              <div className="container">
                <Outlet />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardLayout;
