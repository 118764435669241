import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchProviderBenefit,
  resetManaulClientUpload,
  submitProviderClientsWithCSV,
} from "../features/user/userSlice";
import { AddClientsModal } from "./modal/AddClientsModal";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import AddClientsFile from "./AddClientsFile";
import SuccessModal from "./modal/SuccessModal";
import AddClientSuccess from "./modal/AddClientsSucces";

const AddClients = () => {
  const [closeClientModal, setCloseClientModal] = useState(false);
  const [csvSelected, setCsvSelected] = useState("");
  const [manualClientUploadModalSuccess, setManualClientUploadModalSuccess] =
    useState(false);

  const { submitClientsManuallySuccess } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (csvSelected?.name) {
      const formData = new FormData();
      formData.append("file", csvSelected);
      dispatch(submitProviderClientsWithCSV(formData));
    }
  }, [csvSelected, dispatch]);

  useEffect(() => {
    setManualClientUploadModalSuccess(submitClientsManuallySuccess);
    if (submitClientsManuallySuccess) {
      setCloseClientModal(false);
    }
  }, [submitClientsManuallySuccess]);

  useEffect(() => {
    dispatch(fetchProviderBenefit());
  }, [dispatch]);

  return (
    <div className="addClients mt-40">
      <div className="addClients--header">
        <div className="addClients--header__deets">
          <h1>Add Clients</h1>
          <p>Upload your client details</p>
        </div>
        <AddClientsFile
          setCsvSelected={setCsvSelected}
          csvSelected={csvSelected}
        />
      </div>
      <Button
        block={true}
        variant={"primary"}
        className={"mt-40"}
        // disabled={isLoading ? true : false}
        onClick={() => setCloseClientModal(true)}
      >
        Add Clients Manually
      </Button>
      <Button
        block={true}
        variant={"secondary"}
        className={"mt-40"}
        // disabled={isLoading ? true : false}
        onClick={() => navigate("/")}
      >
        Continue to dashboard
      </Button>
      {closeClientModal && (
        <AddClientsModal
          closeClientModal={closeClientModal}
          setCloseClientModal={setCloseClientModal}
        />
      )}

      <AddClientSuccess
        successModal={manualClientUploadModalSuccess}
        iconID={"checkSuccess"}
        subtext={`You have successfully added a client. `}
        title={`Congratulations!`}
        iconWidth={64}
        iconHeight={64}
        setManualClientUploadModalSuccess={setManualClientUploadModalSuccess}
        setCloseClientModal={setCloseClientModal}
      />
    </div>
  );
};

export default AddClients;
