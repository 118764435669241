import RouteSwitch from "./routes/RouteSwitch";
import "nprogress/nprogress.css";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <RouteSwitch />

      <Toaster
        position="top-right"
        toastOptions={{
          duration: 4000,
          success: {
            style: {
              background: "#EBF7EE",
            },
          },
          error: {
            style: {
              background: "#FFF3F3",
            },
          },
          loading: {
            style: {
              background: "#FEF1DD",
              color: "#F79F1A",
            },
          },
          style: {
            borderRadius: "8px",
            maxWidth: "366px",
            minHeight: "48px",
            boxShadow: "none",
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "17px",
            letterSpacing: "0.01em",
            color: "#000B14",
            textAlign: "left",
            flex: "unset",
          },
        }}
      />
    </>
  );
}

export default App;
