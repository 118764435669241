import React, { useEffect, useRef, useState } from "react";
import Icon from "./Icon";
import csvFile from "../assets/testCSV.csv";
import { useSelector } from "react-redux";
import { limitString } from "../utils/helper";

const AddClientsFile = ({ csvSelected, setCsvSelected }) => {
  const fileInputRef = useRef({});
  const { uploadingCsv, csvSucces } = useSelector((store) => store.user);

  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const handleFileUpload = () => {
    if (!csvSelected) return;

    // Clear any previous errors

    // Create a temporary URL for the uploaded file
    const fileUrl = URL.createObjectURL(csvSelected);
    setUploadedFileUrl(fileUrl);
  };
  useEffect(() => {
    if (csvSucces) {
      handleFileUpload();
    }
  }, [csvSucces]);
  useEffect(() => {
    const clearFileInput = () => {
      // Clear the file input value
      fileInputRef.current.value = "";
    };
    clearFileInput();
  }, []);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  return (
    <div className="addClients--header__info">
      <div className="add--image">
        {csvSucces ? (
          <>
            <Icon id={"bigBlueCheck"} height={"64"} width={"64"} />
            <p className="up-success">File Uploaded Successfully! </p>
            <div className="changeFile">
              <div className="file--deets">
                <span>
                  <Icon id={"template"} height={"27"} width={"18"} />
                </span>
                <p className="file--deets__name">
                  {limitString(csvSelected?.name, 15)}{" "}
                  <span>Uploaded successfully</span>{" "}
                </p>
                <li className="file--deets__size">
                  {formatBytes(csvSelected?.size)}
                </li>
              </div>
              <div className="action--btn">
                <div className="upload__btn">
                  <span>Change File</span>
                  <input
                    type="file"
                    id="file"
                    accept=".csv"
                    className="file-input"
                    onChange={(e) => setCsvSelected(e.target.files[0])}
                    disabled={uploadingCsv}
                    ref={fileInputRef}
                  />
                </div>
                <a
                  href={uploadedFileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View CSV File
                </a>
              </div>
            </div>
          </>
        ) : (
          <>
            <span class="add--image__container"></span>

            <Icon id={"fileIcon"} height={"44"} width={"44"} />

            <div className="image--text">
              <p>
                {uploadingCsv ? (
                  "Uploading..."
                ) : (
                  <>
                    Drag and drop file here or <span>Choose file</span>
                  </>
                )}
              </p>
            </div>
            <input
              type="file"
              id="file"
              accept=".csv"
              className="file-input"
              onChange={(e) => setCsvSelected(e.target.files[0])}
              disabled={uploadingCsv}
            />
          </>
        )}
      </div>
      <p className="mt-16 csv--type">
        Include: first name, last name, phone number, email
      </p>
      <div className="template">
        <div className="template--text">
          <h3>
            <span>
              <Icon id={"template"} height={"27"} width={"18"} />
            </span>
            CSV Template{" "}
          </h3>
          <p>You can download this template as a sample for your file.</p>
        </div>
        <div className="template--btn">
          <a href={csvFile} download>
            <button>
              <Icon id={"downloadIcon"} height={"14"} width={"14"} />
              <span>Download</span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AddClientsFile;
