import React from "react";
import Icon from "../Icon";
import Button from "../Button";
import Modal from "./Modal";

const SuccessModal = ({
  successModal,
  iconID,
  iconWidth,
  iconHeight,
  buttonText,
  title,
  subtext,
  containerClassName,
  buttonAction,
}) => {
  return (
    <Modal
      isHeader={false}
      handleClose={() => {
        buttonAction();
      }}
      show={successModal}
      containerClassName={`success-modal ${containerClassName}`}
    >
      <Icon id={iconID} width={iconWidth} height={iconHeight} />
      <h3>{title}</h3>
      <p className="success__subtext">{subtext}</p>
      <Button
        variant={"primary"}
        block
        onClick={() => {
          buttonAction();
        }}
      >
        {buttonText}
      </Button>
    </Modal>
  );
};

export default SuccessModal;
