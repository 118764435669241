import React from "react";
import {
  AccordionItemButton,
  AccordionItemHeading,
} from "react-accessible-accordion";

const BusinessAccordionHeader = ({ headerText, headerBody }) => {
  return (
    <AccordionItemHeading>
      <AccordionItemButton>
        <h3 className="main--text">{headerText}</h3>
        <p className="sub--text">{headerBody}</p>
      </AccordionItemButton>
    </AccordionItemHeading>
  );
};

export default BusinessAccordionHeader;
