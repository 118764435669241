import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeNavtitle,
  fetchProviderBenefit,
  getProviderSubscribersById,
  updateProviderClients,
} from "../../features/user/userSlice";
import Icon from "../../components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { Input, Select } from "../../components/forms";
import { addFixedDuration, dateFormat, useQuery } from "../../utils/helper";
import Spinner from "../../components/PageLoader";
import SuccessModal from "../../components/modal/SuccessModal";

const ClientInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = useParams();
  const query = useQuery();
  const clientType = query.get("clientType") || false;
  const [editActive, setEditActive] = useState(false);
  useEffect(() => {
    dispatch(fetchProviderBenefit());
  }, [dispatch]);
  const { client, submitClientsManuallyLoading, isLoading, providerBenefit } =
    useSelector((store) => store.user);

  useEffect(() => {
    dispatch(changeNavtitle("Client Information"));
    dispatch(getProviderSubscribersById({ id: route?.id, clientType }));
  }, []);

  const [planId, setPlanId] = useState(null);
  const [planIdSelect, setPlanIdSelect] = useState(null);
  const [duration, setDuration] = useState([]);
  const [selectDuration, setSelectDuration] = useState();
  const [durationPice, setDurationPrice] = useState("");
  const [newDate, setNewDate] = useState("");
  const [successModal, setSuccessModal] = useState(false);

  const updateUserDetails = () => {
    dispatch(
      updateProviderClients({
        clientId: Number(route?.id),
        benefitId: planIdSelect,
        benefitDuration: selectDuration,
        hasPersonalTrainer: true,
        benefitPaymentDate: newDate ? newDate : client?.benefitPaymentDate,
      })
    ).then((res) => {
      if (res?.type === "user/updateProviderClients/fulfilled") {
        setSuccessModal(true);
      }
    });
  };

  useEffect(() => {
    setPlanId(client?.benefit?.id);
  }, [client, route.id]);

  useEffect(() => {
    setSelectDuration(client?.benefitDuration);
  }, [client, route.id]);

  useEffect(() => {
    if (planId) {
      setPlanIdSelect(planId);
    }
  }, [planId, route.id]);

  useEffect(() => {
    if (planIdSelect) {
      const benefit = providerBenefit.find(
        (item) => Number(item?.id) === Number(planIdSelect)
      );

      if (benefit) {
        setDuration(benefit.priceOptions);
      } else {
        setDuration([]);
      }
    }
  }, [planIdSelect, route.id]);

  useEffect(() => {
    if (selectDuration) {
      const benefit = duration.find((item) => item?.duration == selectDuration);

      if (benefit) {
        setDurationPrice(benefit?.price);
      } else {
        setDurationPrice("");
      }
    }
  }, [selectDuration, planIdSelect, client]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          <div className="clients">
            <div className="clients--info__header">
              <p
                className="prev-btn"
                onClick={() => {
                  navigate("/clients");
                }}
              >
                <Icon id="back" width="14" height="14" className="back-icon" />
                Back to Clients
              </p>
              {clientType === "PROVIDER" && (
                <div className="edit--btn">
                  {editActive ? (
                    <>
                      <Button
                        variant="primary"
                        type="submit"
                        className=""
                        block
                        size={"md"}
                        onClick={() => updateUserDetails()}
                      >
                        {submitClientsManuallyLoading
                          ? "Saving..."
                          : "Save Changes"}
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="primary"
                      type="submit"
                      className=""
                      block
                      size={"md"}
                      onClick={() => setEditActive(true)}
                    >
                      <span className="penIcon">
                        <Icon id={"pen"} height={"16"} width={"16"} />
                      </span>
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </div>
            <div className="clients--info__body">
              <div className="deets">
                <div className="deets--header">
                  <h4>Personal Information</h4>
                </div>
                <div className="deets--body">
                  <div className="inputgroup">
                    <Input
                      id="fullname"
                      name="fullname"
                      placeholder="Full Name"
                      label={"Full Name"}
                      disabled
                      value={client?.firstName}
                    />
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email"
                      label={"Emall"}
                      disabled
                      value={client?.lastName}
                    />
                  </div>
                  <div className="inputgroup">
                    <Input
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                      label={"Phone Number"}
                      disabled
                      value={client?.phone}
                      type="tel"
                    />
                    <Input
                      id="dateOfBirth"
                      placeholder="Date of birth"
                      label="Date of birth"
                      name="dateOfBirth"
                      disabled
                      value={client?.dateOfBirth ? client?.dateOfBirt : "Nil"}
                      // onChange={handleChange}
                      maxDate={new Date()}
                      isDate={true}
                    />
                  </div>
                </div>
              </div>
              <div className="deets">
                <div className="deets--header">
                  <h4>Plan Details</h4>
                </div>
                <div className="deets--body">
                  <div className="input-group">
                    <div className="address--deets">
                      <p>Select Plan</p>

                      <Select
                        options={providerBenefit.map((item) => ({
                          name: item?.benefitName,
                          value: item?.id,
                        }))}
                        id="planIdSelect"
                        name="planIdSelect"
                        placeholder={"Select Plan"}
                        disabled={editActive ? false : true}
                        onChange={(e) => {
                          setSelectDuration("");
                          setPlanId(e.target.value);
                        }}
                        value={planIdSelect}
                      />
                    </div>
                    <div className="address--deets">
                      <p>Select Plan Duration</p>
                      <Select
                        options={duration.map((item) => ({
                          name: item?.duration ? item?.duration : null,
                          value: item?.duration ? item?.duration : null,
                        }))}
                        id="benefitDuration"
                        name="benefitDuration"
                        placeholder="Duration"
                        disabled={editActive ? false : true}
                        onChange={(e) => setSelectDuration(e.target.value)}
                        value={selectDuration}
                      />
                    </div>
                    <div className="address--deets">
                      <p>Plan Price</p>
                      <Input
                        id="planPrice"
                        name="=planPrice"
                        placeHolder="Plan Price"
                        isFloatingLabel={false}
                        disabled={true}
                        // onChange={handleChange}
                        value={durationPice}
                      />
                    </div>
                  </div>
                  <div className="input-time">
                    <div className="address--deets">
                      <p>Date Paid</p>
                      <Input
                        id="benefitPaymentDate"
                        placeHolder="MM/DD/YYYY"
                        isFloatingLabel={false}
                        name="benefitPaymentDate"
                        maxDate={new Date()}
                        isDate={true}
                        disabled={editActive ? false : true}
                        value={
                          newDate
                            ? newDate
                            : dateFormat(
                                client?.benefitPaymentDate,
                                "MMM/dd/yyyy"
                              )
                        }
                        //   value={values.benefitPaymentDate}
                        onChange={(e) => setNewDate(e.target.value)}
                      />
                    </div>
                    <div className="address--deets">
                      <p>Renewal Date</p>
                      <Input
                        id="dob"
                        placeHolder="jul, 12 2024"
                        isFloatingLabel={false}
                        name="dob"
                        maxDate={new Date()}
                        isDate={true}
                        disabled={true}
                        value={
                          (newDate || client?.benefitPaymentDate) &&
                          selectDuration
                            ? dateFormat(
                                addFixedDuration(
                                  newDate
                                    ? newDate
                                    : client?.benefitPaymentDate,
                                  selectDuration
                                ),
                                "MMM/dd/yyyy"
                              ) || "mm/dd/yyyy"
                            : "mm/dd/yyyy"
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SuccessModal
            successModal={successModal}
            iconID={"checkSuccess"}
            buttonText={"Continue"}
            subtext={`Your changes have been saved successfully `}
            title={`Changes Saved`}
            iconWidth={64}
            iconHeight={64}
            buttonAction={() => {
              setSuccessModal(false);
              setEditActive(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default ClientInfo;
