import React from "react";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
// import { resendInvitationLink } from '../../features/employees/employeesSlice';

const MobileTable = ({ key, children }) => {
  //   const { addEmployeeLoading } = useSelector((store) => store.employees);
  //   const dispatch = useDispatch();
  return (
    <div className="list-container" key={key}>
      {children}
    </div>
  );
};

export default MobileTable;
