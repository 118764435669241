import React, { useRef } from "react";
import styled from "styled-components";
// import PropTypes from 'prop-types';
import Icon from "../Icon";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";

export const ModalPane = styled.div`
  background: rgba(0, 11, 20, 0.85);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: ${({ show }) =>
    show ? "opacity 0.2s linear" : "visibility 0s 0.3s, opacity 0.2s linear"};
`;
export const ModalContainer = styled.div`
  position: relative;
  max-width: ${({ isModalBig }) => (isModalBig ? "1050px" : "672px")};
  width: 100%;
  .closebtn {
    position: absolute;
    top: -2rem;
    right: -1.25rem;
    cursor: pointer;
    &:hover {
      color: #ff8788;
    }

    @media screen and (max-width: 769.98px) {
      top: 10px;
      margin-right: 1.25rem;
      right: 16px;
      z-index: 30;

      svg {
        width: 35px;
        height: 35px;
      }
    }
  }
`;

export const ModalBody = styled.div`
  max-width: ${({ isModalBig }) => (isModalBig ? "950px" : "672px")};
  width: 90%;
  margin: auto;
  background: #fff;
  border-radius: 16px;
  position: relative;
`;

export const ModalTop = styled.div`
  border-bottom: 1px solid #edeff5;
  padding: 1.5rem 2.5rem;
  position: relative;
  @media screen and (max-width: 575.98px) {
    padding: 1.25rem 1.25rem;
  }

  p {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 24px;

    @media screen and (max-width: 575.98px) {
      font-size: 1rem;
      line-height: 24px;
    }
  }
`;

export const ModalBottom = styled.div`
  border-top: 1px solid #edeff5;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 2rem;
  position: relative;
  button {
    margin-left: 20px;
  }

  @media screen and (max-width: 575.98px) {
    padding: 1.25rem 1.25rem;
  }
`;

export const ModalContent = styled.div`
  padding: 1.8rem;
  height: auto;
  overflow: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  @media screen and (max-width: 575.98px) {
    padding: 2rem 1.25rem;
  }
`;

export default ({
  children,
  containerClassName,
  show = false,
  handleClose,
  hideClose = false,
  title,
  innerRef,
  backdropClose = true,
  isHeader = true,
  hasFixedHeight = false,
  isModalBig = false,
}) => {
  const containerRef = useRef();

  const modalCloseHandler = () => {
    setTimeout(() => {
      !hideClose && handleClose();
    }, 300);
  };

  React.useEffect(() => {
    if (!show && hideClose) {
      modalCloseHandler();
    }

    if (innerRef) innerRef(containerRef);
  }, [show]);

  useDisableBodyScroll(show);

  return (
    <ModalPane
      className="modal-backdrop"
      show={show}
      onClick={(e) => {
        if (backdropClose && e.target.classList.contains("modal-backdrop")) {
          // handleClose();
        }
      }}
    >
      <ModalContainer
        isModalBig={isModalBig}
        className={`modal  ${
          hasFixedHeight && "modal-withheight"
        } ${containerClassName}`}
      >
        {!hideClose && (
          <div className="closebtn">
            <Icon
              width="40"
              height="40"
              id="closeBtn"
              onClick={() => handleClose(true)}
            />
          </div>
        )}

        <ModalBody isModalBig={isModalBig} className="modal-body">
          {isHeader && (
            <ModalTop className="modal-container-top">
              <p className="medium-font">{title}</p>
            </ModalTop>
          )}
          <ModalContent ref={containerRef} className={`modal-content`}>
            {children}
          </ModalContent>
        </ModalBody>
      </ModalContainer>
    </ModalPane>
  );
};
