import React from "react";
import { RadioSwitch } from "../forms/RadioSwitch";
import { convertTo12HourFormat } from "../../utils/helper";

const Schedule = ({ daysOfWeek, setDaysOfWeek }) => {
  const handleCheckboxChange = (index) => {
    const updatedDays = [...daysOfWeek];
    updatedDays[index].isOpen = !updatedDays[index].isOpen;
    if (!updatedDays[index].isOpen) {
      updatedDays[index].openTime = "";
      updatedDays[index].closeTime = "";
    }
    setDaysOfWeek(updatedDays);
  };

  // Handle time input change
  const handleTimeChange = (index, field, event) => {
    const updatedDays = [...daysOfWeek];
    updatedDays[index][field] = event.target.value; // field is 'openTime' or 'closeTime'
    setDaysOfWeek(updatedDays);
  };

  return (
    <>
      {daysOfWeek?.map((day, index) => (
        <>
          <div className="opening__hours" key={index}>
            <div className="time__switch">
              <p>{day?.day}</p>
              <RadioSwitch
                handleChange={() => handleCheckboxChange(index)}
                day={day}
              />
            </div>

            <div className="time--section">
              <div className={`time--bar ${!day.isOpen ? "disabled" : ""}`}>
                <span class="time-toggle-button"></span>
                <p>
                  {day.openTime
                    ? convertTo12HourFormat(day.openTime)
                    : "--:-- AM"}
                </p>
                <input
                  type="time"
                  id="time"
                  className="timepicker-input"
                  disabled={!day.isOpen ? true : false}
                  value={day.openTime}
                  onChange={(event) =>
                    handleTimeChange(index, "openTime", event)
                  }
                />
              </div>
              <span>To</span>
              <div className={`time--bar ${!day.isOpen ? "disabled" : ""}`}>
                <span class="time-toggle-button"></span>
                <p>
                  {day.closeTime
                    ? convertTo12HourFormat(day.closeTime)
                    : "--:-- AM"}
                </p>
                <input
                  type="time"
                  id="time"
                  className="timepicker-input"
                  disabled={!day.isOpen ? true : false}
                  value={day.closeTime}
                  onChange={(event) =>
                    handleTimeChange(index, "closeTime", event)
                  }
                />
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default Schedule;
