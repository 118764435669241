import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/forms/Input";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../features/user/userSlice";
import nProgress from "nprogress";

const Login = () => {
  const { user, isLoading, changePassword } = useSelector(
    (store) => store.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schema = Yup.object({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Required"),
  });
  const { handleSubmit, handleChange, handleBlur, touched, values, errors } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
        localStorage.setItem("loginData", JSON.stringify(values.username));

        dispatch(
          loginUser({
            username: values.username,
            password: values.password,
          })
        );
      },
    });

  useEffect(() => {
    if (isLoading) {
      nProgress.start();
    } else {
      nProgress.done();
    }
  }, [isLoading]);

  useEffect(() => {
    if (user?.data?.token) {
      navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    if (changePassword) {
      navigate("/auth/reset-password");
    }
  }, [changePassword]);
  return (
    <div className="auth__form--content">
      <div className="auth__form--header">
        <div className="header__container">
          <div className="header__container--content">
            <h3 className="tx-c">Welcome!</h3>
            <p className="tx-c">Login to manage your clients.</p>
          </div>
        </div>
      </div>
      <form className="__form" onSubmit={handleSubmit}>
        <Input
          type="text"
          id="username"
          value={values.username}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Username"
          name="username"
          error={
            touched.username && errors.username ? (
              <small className="error">{errors.username}</small>
            ) : null
          }
        />

        <Input
          type="password"
          id="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Password"
          name="password"
          error={
            touched.password && errors.password ? (
              <small className="error">{errors.password}</small>
            ) : null
          }
        />

        <Button
          variant="primary"
          type="submit"
          className="mt-40"
          block
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Log in"}
        </Button>
      </form>
      <p className="mt-32 tx-c extralinnk">
        {"You don't have a provider account?"}{" "}
        <Link className="btn btn--link btn--link-secondary" to="/auth/signup">
          Create an account
        </Link>
      </p>
    </div>
  );
};

export default Login;
