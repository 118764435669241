export const navigations = [
  {
    id: 0,
    link: "/",
    icon: "dash",
    name: "Dashboard",
    navName: "",
    coloredIcon: "blue-dash",
  },
  {
    id: 1,
    link: "/clients",
    icon: "clients",
    name: "Clients",
    navName: "clients",
    coloredIcon: "blue-clients",
  },
  {
    id: 2,
    link: "/plans",
    icon: "list-plan",
    name: "Price Plans",
    navName: "plans",
    coloredIcon: "list-blues",
  },
];
