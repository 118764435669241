import React from "react";
import TextAreaField from "./forms/TextArea";
import { Input, Select } from "./forms";

const AddBusinessAddressSection = ({
  handleChange,
  values,
  allState,
  stateLga,
}) => {
  return (
    <>
      <div className="input-group">
        <div className="address--deets">
          <p>Country</p>
          <Select
            options={[{ name: "Nigeria", value: "nigeria" }]}
            id="selectedCountry"
            name="selectedCountry"
            placeholder="Country"
            onChange={handleChange}
            value={values.selectedCountry}
          />
        </div>
        <div className="address--deets">
          <p>State/Region</p>
          <Select
            options={allState.map((item) => ({
              name: item.name,
              value: item.id,
            }))}
            id="selectedState"
            name="selectedState"
            placeholder="State/Region"
            onChange={handleChange}
            value={values.selectedState}
          />
        </div>
      </div>

      <div className="input-group">
        <div className="address--deets">
          <p>City</p>
          <Select
            options={stateLga.map((item) => ({
              name: item.name,
              value: item.name,
            }))}
            id="selectedLga"
            name="selectedLga"
            placeholder="City"
            onChange={handleChange}
            value={values.selectedLga}
          />
        </div>
        <div className="address--deets">
          <p>Zip Code</p>
          <Input
            id="zipCode"
            name="zipCode"
            placeHolder="500100"
            type="number"
            onChange={handleChange}
            value={values.zipCode}
            isFloatingLabel={false}
            onKeyDown={(e) =>
              ["e", "E", "-"].includes(e.key) && e.preventDefault()
            }
          />
        </div>
      </div>

      <div className="input-group">
        <div className="address--deets">
          <p>Street Address</p>
          <TextAreaField
            id="streetAddress"
            placeholder="Address"
            value={values.streetAddress}
            name="streetAddress"
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default AddBusinessAddressSection;
