import React from "react";
import AuthLayout from "../layout/AuthLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import ResetPassword from "../pages/auth/ResetPassword";
import DashboardLayout from "../layout/DashboardLayout";
import Dashboard from "../pages/app/Dashboard";
import { RequiredAuth } from "../hooks/RequiredAuth";
import SignUp from "../pages/auth/SignUp";
import Onboarding from "../pages/app/Onboarding";
import Clients from "../pages/app/Clients";
import ClientInfo from "../pages/app/ClientInfo";
import Plan from "../pages/app/Plan";
import Settings from "../pages/app/Settings";
import Account from "../pages/settings/Account";
import KeyPerson from "../pages/settings/KeyPerson";
import Security from "../pages/settings/Security";

const RouteSwitch = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/signup" element={<SignUp />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
        </Route>
        <Route element={<RequiredAuth />}>
          <Route path="/" element={<DashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/:id" element={<ClientInfo />} />
            <Route path="/plans" element={<Plan />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/settings" element={<Settings />}>
              <Route index element={<Account />} />
              <Route path="/settings/keyperson" element={<KeyPerson />} />
              <Route path="/settings/security" element={<Security />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RouteSwitch;
