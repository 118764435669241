import React, { useState } from "react";
import Icon from "../Icon";

const SelectBox = ({
  handleCheckboxChange,
  dropDownItem,
  checkedItems,
  setCheckedItems,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  return (
    <>
      <div
        className="selectbox"
        onMouseOver={() => setOpenDropdown(true)}
        onMouseLeave={() => setOpenDropdown(false)}
      >
        <div className="selectbox__field">
          <p className={`${checkedItems?.length > 0 ? "active" : ""}`}>
            {checkedItems?.length > 0
              ? checkedItems.map((item) => (
                  <>
                    {item}
                    <span>,</span>{" "}
                  </>
                ))
              : "Select Service category"}{" "}
          </p>
          <Icon id="dropdown" width="16" height="11" />
        </div>
        {openDropdown && (
          <div className="selectbox__dropdown">
            <ul className="select">
              {dropDownItem?.length > 0 &&
                dropDownItem?.map((item, index) => (
                  <li key={index}>
                    <input
                      type="checkbox"
                      id={index}
                      name={item?.name}
                      value={item?.name}
                      onChange={() => handleCheckboxChange(item)}
                      checked={checkedItems.includes(item.value)}
                    />
                    <label htmlFor={item?.name}>{item?.name}</label>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectBox;
