import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getUserFromLocalStorage } from "../utils/localstorage";

export const RequiredAuth = () => {
  const auth = getUserFromLocalStorage();
  const location = useLocation();

  return auth?.data?.token ? (
    <Outlet />
  ) : (
    <Navigate to="/auth/login" state={{ from: location }} replace={true} />
  );
};
