import React from "react";

const TextAreaField = ({
  className,
  label,
  name,
  onChange,
  value = "",
  error,
  placeholder = "",
  disabled,
  rows = 4,
  cols = 50,
  onBlur,
  // testid,
  touched,
  maxLength = "",
  // style,
  isFloatingLabel = true,
  labelClassName,
  placeHolder,
}) => {
  const [isDisabled, setDisabled] = React.useState(true);
  const labelRef = React.useRef(null);

  React.useEffect(() => {
    // initially slide up if there is a value
    if (value) labelRef.current.classList.add("active");

    const timer = setTimeout(() => {
      setDisabled(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [value]);

  const handleFocus = (e) => {
    const target = e.target;
    target.setAttribute("autocomplete", "off");
    if (!target.value) {
      target.parentNode.classList.remove("active");
    }
    target.parentNode.classList.add("active");
    target.setAttribute("placeholder", target.getAttribute("data-placeholder"));
  };

  // remove active class and placeholder
  const handleBlur = (e) => {
    const target = e.target;
    e.target.setAttribute("autocomplete", "off");
    if (!target.value) {
      target.parentNode.classList.remove("active");
    }
    target.removeAttribute("placeholder");

    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={`form-group ${
        isFloatingLabel ? "floating-label" : ""
      } ${className}`}
      // style={style}
      ref={labelRef}
    >
      <label htmlFor={label} className={labelClassName} ref={labelRef}>
        {label}
      </label>
      <textarea
        name={name}
        value={value}
        className={`form-control w-100p textArea ${className}`}
        rows={rows}
        cols={cols}
        maxLength={maxLength}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        data-placeholder={placeholder}
        placeholder={placeholder}
        disabled={disabled || isDisabled}
      ></textarea>
      {error}
    </div>
  );
};

export default TextAreaField;
