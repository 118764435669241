import React from "react";
import { Input } from "./forms";

const FeaturesSection = ({
  features,
  setFeatures,
  values,
  setFieldValue,
  addFeature,
  setAddFeature,
  checkedFeature,
  name,
}) => {
  return (
    <>
      {" "}
      {features.map((feature, index) => (
        <label key={index}>
          <input
            type="checkbox"
            name={`${checkedFeature}[${index}]`}
            checked={values[index]}
            onChange={() =>
              setFieldValue(
                `${checkedFeature}[${index}]`,
                !values[index]
              )
            }
          />
          {feature}
        </label>
      ))}
      <div className="add--items">
        <p>Enter {name} not listed</p>
        <div className="add--items__input">
          <div className="add--input">
            <Input
              id={name}
              name={name}
              placeHolder={name}
              isFloatingLabel={false}
              value={addFeature}
              onChange={(e) => setAddFeature(e.target.value)}
            />
          </div>
          <button
            onClick={() => {
              setFeatures([...features, addFeature]);
              setAddFeature("");
            }}
          >
            Add
          </button>
        </div>
      </div>
    </>
  );
};

export default FeaturesSection;
