import React, { useState } from "react";
import Icon from "./Icon";
import Button from "./Button";

const SearchAddContainer = ({
  searchInput,
  changeInput,
  handleCLick,
  btnTitle,
  filterOptions,
  selectedFilter,
  setSelectedFilter,
}) => {
  const [openFilterList, setOpenFilterList] = useState(false);

  return (
    <div className="search__header">
      <div className="search__header--container">
        {filterOptions && (
          <div
            className="search__header--filterContainer"
            onMouseLeave={() => setOpenFilterList(false)}
          >
            <div
              className="search__header--filter"
              onMouseOver={() => setOpenFilterList(true)}
            >
              <p>{selectedFilter?.name ? selectedFilter?.name : "Filter by"}</p>
              <Icon id="down-caret" width="10" height="4" />
            </div>
            {openFilterList && (
              <div className="filterList">
                <ul>
                  {filterOptions.map((item, index) => (
                    <li
                      onClick={() => {
                        setSelectedFilter(item);
                      }}
                      key={index}
                    >
                      {item?.name}
                    </li>
                  ))}

                  {/* <li>Pending</li>
									<li>Expired</li>
									<li>Failed</li> */}
                </ul>
              </div>
            )}
          </div>
        )}
        <div className="search__header--search">
          <Icon width="16" height="16" id="search" />
          <input
            type="text"
            id="savingsAmount"
            value={searchInput}
            onChange={changeInput}
            placeholder="Search name"
          />
        </div>
      </div>
      {/* <div className="search__header--btn">
        {btnTitle && (
          <Button
            variant="primary"
            size="sm"
            className="btn--block"
            onClick={handleCLick}
            // disabled={loader}
          >
            {btnTitle}
          </Button>
        )}
      </div> */}
    </div>
  );
};

export default SearchAddContainer;
