import React, { useEffect, useState } from "react";
import {
  changeNavtitle,
  fetchProviderBenefit,
  getProviderCredit,
} from "../../features/user/userSlice";
import { useDispatch, useSelector } from "react-redux";
import PlanPriceModal from "../../components/modal/PlanPriceModal";
import Button from "../../components/Button";
import NewPlanModal from "../../components/modal/AddNewlanModal";
import Icon from "../../components/Icon";

const Plan = () => {
  const dispatch = useDispatch();
  const { providerBenefit } = useSelector((store) => store.user);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [plan, setPlan] = useState({});
  const [createPlan, setCreatePLan] = useState(false);
  useEffect(() => {
    dispatch(fetchProviderBenefit());
    dispatch(changeNavtitle("Plan Price"));
    dispatch(getProviderCredit());
  }, []);

  return (
    <div className="plan">
      <div className="planPricing">
        {providerBenefit.map((plan, index) => (
          <>
            <div className="plan__container" key={index}>
              <div className="plan__name">
                <span>{index + 1}.</span>
                <p>{plan?.benefitName}</p>
              </div>
              <div
                className="plan__details"
                onClick={() => {
                  setShowPriceModal(true);
                  setPlan(plan);
                }}
              >
                <p>SEE DETAILS</p>
                <div className="sm--details">
                  <Icon id={"rightArrow"} height={"24"} width={"24"} />
                </div>
              </div>
            </div>
          </>
        ))}

        <Button
          variant="primary"
          type="submit"
          className="mt-24"
          block
          size={"md"}
          onClick={() => {
            setCreatePLan(true);
          }}
        >
          + Add New Plan
        </Button>
      </div>
      {showPriceModal && (
        <PlanPriceModal
          setShowPriceModal={setShowPriceModal}
          showPriceModal={showPriceModal}
          plan={plan}
        />
      )}
      {createPlan && (
        <NewPlanModal createPlan={createPlan} setCreatePLan={setCreatePLan} />
      )}
    </div>
  );
};

export default Plan;
