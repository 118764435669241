import React, { useState } from "react";
import Icon from "../Icon";

const BigModal = ({
  children,
  isModalOpen,
  isVisible,
  handleClose,
  headerTitle,
}) => {
  return (
    <div>
      {isVisible && (
        <div className={`modal-overlay-bg ${isModalOpen ? "active" : ""}`}>
          <div className="modal-content-bg">
            <div className="modal-content-bg__header">
              <p>{headerTitle}</p>
              <div className="closebtn">
                <Icon
                  width="40"
                  height="40"
                  id="closeBtn2"
                  onClick={handleClose}
                />
              </div>
            </div>

            <div className="modal-content-bg__body">{children}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BigModal;
