import React from "react";
import Icon from "./Icon";

const BusinessInfoImage = ({
  addImages,
  visibleImageInputs,
  handleImageMultipleChange,
  handleCheckboxChange,
  showMoreInputs,
}) => {
  return (
    <>
      <div className="add--images">
        {addImages.slice(0, visibleImageInputs).map((addImg, index) => (
          <div className="add--images__container" key={index}>
            <div className="add--image">
              <>
                {addImg?.image ? (
                  <>
                    <img
                      className="selected--img"
                      src={addImg?.image}
                      alt={`selected ${index + 1}`}
                    />
                  </>
                ) : (
                  <>
                    <span class="add--image__container"></span>

                    <Icon id={"imageIcon"} height={"44"} width={"44"} />

                    <div className="image--text">
                      <p>
                        <span>Click to upload</span> or drag images here
                      </p>
                    </div>
                    <span>upload (.jpg, .jpeg, .png) file</span>
                    <input
                      type="file"
                      className="file-input"
                      accept=".jpg, .jpeg, .png"
                      onChange={(event) =>
                        handleImageMultipleChange(event, index)
                      }
                    />
                  </>
                )}
              </>
            </div>
            <div className="radio__container">
              <input
                type="radio"
                name="cover"
                id=""
                checked={addImages.isCoverphoto}
                onChange={() => handleCheckboxChange(index)}
              />
              <label htmlFor="cover">Use as cover photo</label>
            </div>
          </div>
        ))}
      </div>
      <button onClick={showMoreInputs}>+ Add New Image</button>
    </>
  );
};

export default BusinessInfoImage;
