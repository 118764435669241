import React from "react";
import { Input } from "../../components/forms";
import Icon from "../../components/Icon";
import Button from "../../components/Button";

const Security = () => {
  return (
    <div className="security">
      <div className="security__header">
        <h3>Change Password</h3>
        <p>
          Provide your current and new password to successfully change your
          password.
        </p>
      </div>
      <div className="security__body">
        <div className="security__body--left">
          <h4>Passwords must contain</h4>
          <ul>
            <li>
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              8 or more characters
            </li>
            <li>
              {" "}
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              An uppercase letter (A-Z)
            </li>
            <li>
              {" "}
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              A lowercase letter (a-z)
            </li>
            <li>
              {" "}
              <span>
                <Icon id={"disc"} height={"10"} width={"10"} />{" "}
              </span>{" "}
              A lowercase letter (a-z)
            </li>
          </ul>
        </div>
        <div className="security__body--right">
          <Input
            type="password"
            id="password"
            // value={values.password}
            // onChange={handleChange}
            // onBlur={handleBlur}
            label="Password"
            name="password"
            // error={
            //   touched.password && errors.password ? (
            //     <small className="error">{errors.password}</small>
            //   ) : null
            // }
          />
          <Input
            type="password"
            id="password"
            // value={values.password}
            // onChange={handleChange}
            // onBlur={handleBlur}
            label="Password"
            name="password"
            // error={
            //   touched.password && errors.password ? (
            //     <small className="error">{errors.password}</small>
            //   ) : null
            // }
          />
          <div className="btns">
            <Button
              variant="secondary"
              type="submit"
              className="update"
              size={"md"}
            >
              <>
                {" "}
                {/* <span className="penIcon">
                <Icon id={"pencil"} height={"21"} width={"21"} />
              </span> */}
                {""}Cancel
              </>
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="update"
              size={"md"}
            >
              <>
                {" "}
                {/* <span className="penIcon">
                <Icon id={"pencil"} height={"21"} width={"21"} />
              </span> */}
                {""}Save Changes
              </>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Security;
