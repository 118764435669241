import React, { useEffect } from "react";
import Modal from "./Modal";
import AddClientsFile from "../AddClientsFile";
import { useDispatch } from "react-redux";
import {
  getProviderSubscribers,
  submitProviderClientsWithCSV,
} from "../../features/user/userSlice";
import Button from "../Button";

const AddClientsFIleModal = ({
  openAddClientsCsvModal,
  setOpenAddClientsCsvModal,
  setCsvSelected,
  csvSelected,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (csvSelected?.name) {
      const formData = new FormData();
      formData.append("file", csvSelected);
      dispatch(submitProviderClientsWithCSV(formData));
    }
  }, [csvSelected, dispatch]);
  return (
    <Modal
      isModalBig={true}
      title={"Import Clients"}
      handleClose={() => {
        setOpenAddClientsCsvModal(false);
      }}
      show={openAddClientsCsvModal}
      containerClassName="addClients--modal"
    >
      <div className="addClients">
        <div className="addClients--header">
          <AddClientsFile
            setCsvSelected={setCsvSelected}
            csvSelected={csvSelected}
          />
        </div>
      </div>
      <Button
        block={true}
        variant={"primary"}
        className={"mt-40"}
        // disabled={isLoading ? true : false}
        onClick={() => {
          setOpenAddClientsCsvModal(false);
          setCsvSelected("");
          dispatch(getProviderSubscribers());
        }}
      >
        Done
      </Button>
    </Modal>
  );
};

export default AddClientsFIleModal;
