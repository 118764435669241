import React from "react";
import Icon from "../Icon";

export default ({
  label,
  options = [],
  onChange,
  disabled,
  error,
  name,
  placeholder,
  value,
  touched,
  onBlur,
  hideLabel,
  selectLabel = "floating-label",
  isFloatingLabel = true,
  className,
  emptyState,
}) => {
  return (
    <div
      className={`form-group ${!isFloatingLabel ? "" : selectLabel} ${
        className ? className : ""
      }`}
    >
      {!hideLabel && <label htmlFor="select">{label}</label>}
      <select
        className={`form-control ${hideLabel ? "py-0" : ""}`}
        onChange={onChange}
        disabled={disabled}
        name={name}
        value={value}
        onBlur={onBlur}
      >
        <option value="" className="select--placeholder" disabled>
          {placeholder}
        </option>
        {options.map((option, i) => (
          <option
            value={option.value}
            key={i}
            disabled={option.disabled || false}
          >
            {option.name}
          </option>
        ))}
        {emptyState && (
          <option value="" className="select--placeholder" disabled>
            {emptyState}
          </option>
        )}
      </select>
      <span className="input-icon">
        <Icon width="12" height="8" id="drop-icon" />
      </span>
      {error}
    </div>
  );
};
