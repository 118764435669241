import React from "react";

export const RadioSwitch = ({ handleChange, day }) => {
  return (
    <div className="toggle--container">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={day?.day}
          id={day?.day}
          checked={day.isOpen}
          onChange={handleChange}
        />
        <label className="label" htmlFor={day?.day}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
      {day.isOpen ? "Open" : "Closed"}{" "}
    </div>
  );
};
